console.log('top');

/*トップMV*/
// $(function(){
//   setTimeout(function() {
//     $(".ttl_fade1").addClass("show");
//   }, 1000);
//   // setTimeout(function() {
//   //   $(".text").addClass("show");
//   // }, 2000);
//   setTimeout(function() {
//     $(".ttl_fade1").removeClass("show");
//     $(".ttl_fade1").addClass("hdn");
//     $(".imgbox3").addClass("show");
//   }, 5000);
//   setTimeout(function() {
//     $(".imgbox").addClass("slide");
//   }, 4100);
//     setTimeout(function() {
//     $(".text").removeClass("hdn");
//     $(".ttl_fade2, .text, .ttl_fade2_1").addClass("show");
//   }, 7000);
//   // setTimeout(function() {
//   //   $(".imgbox3").addClass("show");
//   // }, 9000);
//   // setTimeout(function() {
//   //   $(".text").removeClass("hdn");
//   //   $(".imgbox2, .ttl_fade2, .text").addClass("show");
//   // }, 10000);
//   // setTimeout(function() {
//   //   $(".skip").addClass("hdn");
//   //   $('header').addClass('show');
//   // }, 11000);
//   // setTimeout(function() {
//   //   $(".mainMV").addClass("hdn");
//   //   $(".sld_cover").addClass("show");
//   // }, 12000);
//   setTimeout(function() {
//     $(".skip").addClass("hdn");
//     $('header').addClass('show');
//   },8000);
//   setTimeout(function() {
//     $(".mainMV").addClass("hdn");
//     $(".sld_cover").addClass("show");
//   }, 9000);
// });

$(function(){
  setTimeout(function() {
    $(".sld_cover").addClass("show");
  }, 500);
  setTimeout(function() {
    $('header').addClass('show');
  },1500);
});


/*SKIP*/
// $('.skip').on('click',function (){
//   setTimeout(function() {
//     $(".skip, .ttl_fade1, .text").addClass("hdn");
//     $(".imgbox").addClass("slide");
//   }, 1000);
//   setTimeout(function() {
//     $(".text").removeClass("hdn");
//     $("header, .imgbox3, .imgbox2, .text, .ttl_fade2").addClass("show");
//   }, 4000);

// });

$('.skip').on('click',function (){
  setTimeout(function() {
    $(".skip, .ttl_fade1, .text").addClass("hdn");
    $(".imgbox").addClass("slide");
  }, 1000);
  setTimeout(function() {
    $(".text").removeClass("hdn");
    $("header, .text, .ttl_fade2").addClass("show");
  }, 3500);
  // setTimeout(function() {
  //   $(".text").removeClass("hdn");
  //   $("header, .text, .ttl_fade2").addClass("show");
  // }, 4000);

});

  /*マウスストーカー*/
//   $(function(){
//     const cursor = $("#js-cursor");
//     const mouse = $("#js-mouse");
//     $(".js-area").on("mousemove",function(e){
//         const x=e.clientX;
//         const y=e.clientY;
//         cursor.css({
//             "opacity": "1",
//             "transform": "translate(" + x + "px," + y + "px)",
//         });
//         setTimeout(function(){
//             mouse.css({
//                 "opacity": "1",
//                 "transform": "translate(" + x + "px," + y + "px)",
//             });
//         },150);
//         $("a").on({
//             "mouseenter": function() {
//                 mouse.addClass("js-hover");
//                 cursor.addClass("js-hover");
//             },
//             "mouseleave": function() {
//                 mouse.removeClass("js-hover");
//                 cursor.removeClass("js-hover");
//             }
//         });
//         $('.js-area').on("mouseout",function(e) {
//             cursor.css({
//                 "opacity":"0",
//             });
//             setTimeout(function(){
//                 mouse.css({
//                     "opacity": "0",
//                 });
//             },150);
//         })
//     });
// });

/*全画面スクロール*/
// gsap.set('.sl02, .sl03, .sl04', {
//   autoAlpha: 0,
// })
// const tl1 = gsap.timeline()
// .to('.sl02', {
//   autoAlpha: 1,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_01',
// 	   start: 'top top',
// 	   end: 'center 50px',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
//    }
// })
// .to('.sec_ttl2', {
//   duration:3,
//   scrollTrigger: {
//     trigger: '.sec_ttl2',
//     toggleClass: {
//       targets: '.sec_ttl2',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })
// .to('.fadeInLeft_2', {
//   duration:5,
//   scrollTrigger: {
//     trigger: '.fadeInLeft_2',
//     toggleClass: {
//       targets: '.fadeInLeft_2',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })
// .to('.sl03', {
//   autoAlpha: 1,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_02',
// 	   start: 'top top',
// 	   end: 'center 50px',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
//    }
// })
// .to('.sec_ttl3', {
//   duration:3,
//   scrollTrigger: {
//     trigger: '.sec_ttl3',
//     toggleClass: {
//       targets: '.sec_ttl3',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })
// .to('.fadeInLeft_3', {
//   duration:5,
//   scrollTrigger: {
//     trigger: '.fadeInLeft_3',
//     toggleClass: {
//       targets: '.fadeInLeft_3',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })
// .to('.sl04', {
//   autoAlpha: 1,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_03',
// 	   start: 'top top',
// 	   end: 'center 50px',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
//    }
// })
// .to('.sec_ttl4', {
//   duration:3,
//   scrollTrigger: {
//     trigger: '.sec_ttl4',
//     toggleClass: {
//       targets: '.sec_ttl4',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })
// .to('.fadeInLeft_4', {
//   duration:5,
//   scrollTrigger: {
//     trigger: '.fadeInLeft_4',
//     toggleClass: {
//       targets: '.fadeInLeft_4',
//       className: "show",
//       toggleActions: 'play none none reverse',
//       scrub: true,
//   },
//   }
// })


// gsap.fromTo('.sl02', {
// 	autoAlpha: 0,
// 	},
// 	{
// 	autoAlpha: 1,
//   zIndex:200,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_01',
// 	   start: 'top top',
// 	   end: 'center 50px',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
// 	 }
//    });
//    gsap.fromTo('.sl03', {
// 	autoAlpha: 0
//    },
//    {
// 	autoAlpha: 1,
//   zIndex:300,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_02',
// 	   start: 'top top',
// 	   end: 'center 50px',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
// 	 }
//    });
//    gsap.fromTo('.sl04', {
// 	autoAlpha: 0
//    },
//    {
// 	autoAlpha: 1,
//   zIndex:400,
// 	 scrollTrigger: {
// 	   trigger: '.slsc_03',
// 	   start: 'top top',
// 	   toggleActions: 'play none none reverse',
// 	   scrub: true,
// 	   markers: false,
// 	 }
//    });

//    ScrollTrigger.create({
// 	trigger: '.slid_wrap',
// 	pin: true,
// 	markers: false,
// 	// end: 'bottom -200%', //などと設定するとfixedの期間がより短くなる
// 	// end: 'bottom -450%',
// 	end: 'bottom -450%',
//   })


  /**/
//   $(window).scroll(function() {
//     let scrollTop = $(window).scrollTop(); // 現在のスクロール位置
//     let element = $(".change-color"); // 対象の要素

//     if (element.length > 0) {
//         let elementTop = element.offset().top; // 要素のページ上端からの位置
//         let elementHeight = element.outerHeight(); // 要素の高さ

//         // 要素の範囲内にあるかどうかを判定
//         if (scrollTop > elementTop && scrollTop < elementTop + elementHeight) {
//           $(".scrolldown1").addClass("mix"); // スクロールが対象エリアに入った場合
//         } else {
//           $(".scrolldown1").removeClass("mix"); // スクロールが対象エリアから出ている場合
//         }
//     }
// });

  $(window).scroll(function() {
    let scrollTop = $(window).scrollTop(); // 現在のスクロール位置
    let footerTop = $('footer').offset().top; // footer要素のページ上端からの位置
    let footerHeight = $('footer').outerHeight(); // footer要素の高さ
    let windowHeight = $(window).height(); // ウィンドウの高さ

    // footerの範囲内にあるかどうかを判定
    if (scrollTop + windowHeight > footerTop && scrollTop < footerTop + footerHeight) {
      $(".scrolldown1").addClass("hdn"); // スクロールが対象エリアに入った場合

    } else {
      $(".scrolldown1").removeClass("hdn"); // スクロールが対象エリアから出ている場合
    }
});

//   $(function(){
//     // const content = ".change-color";
//     const showClass = "mix"; 

//     $(".change-color").each(function(index, content){
//         $(window).on("load scroll resize", function(){
//             const windowHeight = $(window).height();
//             const scroll = $(window).scrollTop();
//             const offset = $(content).offset().top;
//             const setTiming = windowHeight * 0.5;
//             const contentHeight = $(content).height();
//             const outTiming = windowHeight * 0.6;

//             if (scroll + windowHeight >= offset + setTiming && scroll + windowHeight < offset + contentHeight + outTiming){
//             $('.scrolldown1').addClass(showClass);
//             } else {
//             $('.scrolldown1').removeClass(showClass);
//             }
//         });
//     });
// });
